// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Temporarily paused (07/06/2023) --> Not enough usage
// import { Crisp } from 'crisp-sdk-web';
// Crisp.configure('0cfc3360-6ad2-4400-9439-20efbf5f0ec6');

// Visual modules
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BootstrapVue } from 'bootstrap-vue';
import 'flag-icon-css/css/flag-icon.css';

// Functional modules
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import AsyncComputed from 'vue-async-computed';
import moment from 'moment';
import {
  Field as VeeField,
  Form as VeeForm,
  ErrorMessage as VeeErrorMessage,
  configure,
} from 'vee-validate';
import './plugins/vee-validate.js';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import App from './App.vue';

// Global imports
import { store } from '@/store/index.js';
import { router } from '@/router/index.js';
import configPlugin from '@/config';
import { getAsset } from '@/utils/assets.js';

// Mixins
import utilsMixin from '@/components/lib/mixins/utils';

// Global directives
import expand from '@/components/lib/directives/expand.js';
import focused from '@/components/lib/directives/focused.js';
import unfocused from '@/components/lib/directives/unfocused.js';

// Old generic components which are should be replaced (from /lib folder)
import PlatformLoading from '@/components/lib/other/Loading.vue';
import Tooltip from '@/components/lib/other/Tooltip.vue';
import PlatformLink from '@/components/lib/buttons/Link.vue';
import PlatformDropdown from '@/components/lib/buttons/Dropdown.vue';
import PlatformCollapseTrigger from '@/components/lib/buttons/CollapseTrigger.vue';
import PlatformDropdownItem from '@/components/lib/buttons/DropdownItem.vue';
import PlatformTextArea from '@/components/lib/forms/FormTextArea.vue';
import PlatformFormGroup from '@/components/lib/forms/FormGroup.vue';
import PlatformAlert from '@/components/lib/alerts/Alert.vue';

const app = createApp(App);

app.use(AsyncComputed);
app.use(BootstrapVue);
app.use(VueCookies);

// Registering global mixins:
app.mixin(utilsMixin);
app.mixin({
  methods: {
    getAsset,
  },
});

// Registering global directives
app.directive('expand', expand),
app.directive('focused', focused),
app.directive('unfocused', unfocused);

// TODO: replace with new components (with OR* prefix)
app.component('loading', PlatformLoading);
app.component('tooltip', Tooltip);
app.component('direct-link', PlatformLink);
app.component('dropdown', PlatformDropdown);
app.component('collapse-trigger', PlatformCollapseTrigger);
app.component('dropdown-item', PlatformDropdownItem);
app.component('form-text-area', PlatformTextArea);
app.component('form-group', PlatformFormGroup);
app.component('alert', PlatformAlert);

// Vee-validate components
app.component('VeeField', VeeField);
app.component('VeeForm', VeeForm);
app.component('VeeErrorMessage', VeeErrorMessage);

configure({
  validateOnInput: true,
});

app.config.globalProperties.$axios = axios;
// TODO: check for vue 3 compatibility
app.config.globalProperties.$appConfig = configPlugin;
app.config.globalProperties.moment = moment;

Sentry.init({
  app,
  dsn: 'https://99826b9e29cbaecdcbb8f49d3bd4bcfd@o351729.ingest.us.sentry.io/4506971520499712',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['*'],
    }),
  ],
  environment: import.meta.env.VITE_ENV,
  // Don't send errors to Sentry in local development
  enabled: import.meta.env.VITE_ENV !== 'local',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['*'],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store);
app.use(router);

router.isReady().then(() => {
  app.mount('#app-entry-point');
});
