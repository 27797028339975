export default {
  route: null,
  api_domain: import.meta.env.VITE_API_ROOT,
  id: null,
  email: '',
  profile_image: '',
  section_focused: '',
  first_name: '',
  last_name: '',
  cache_data: {},
  logged_in: false,
  alert: {
    show: false,
    message: '',
    type: 'info',
  },
  is_analyzed: false,
  redirectUrl: '',
};
