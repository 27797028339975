// eslint-disable-next-line import/prefer-default-export
export const mutations = {
  removeUserInfos(state) {
    state.id = null;
    state.email = '';
    state.first_name = '';
    state.last_name = '';
    state.profile_image = '';
  },
  setLoggedIn(state, logged_in) {
    state.logged_in = logged_in;
  },
  changeAlertInfos(state, alert) {
    state.alert.show = alert.show;
    state.alert.type = alert.type;
    state.alert.message = alert.message;
  },
  setFocusedSection(state, section) {
    state.section_focused = section;
  },
  setUserId(state, id) {
    state.id = id;
  },
  setUserEmail(state, email) {
    state.email = email;
  },
  setFirstName(state, firstName) {
    state.first_name = firstName;
  },
  setLastName(state, lastName) {
    state.last_name = lastName;
  },
  setRoute(state, route) {
    state.route = route;
  },
  setRedirectUrl(state, redirectUrl) {
    state.redirectUrl = redirectUrl;
  },
};
