<template>
    <transition mode="out-in" name="slide-fade">
      <div
        :class="['sidebar-container', {'sidebar-hover': isHovered && !isMobile, 'active': mobileSidebarOpened}]"
        @click="checkIfClickedOutside"
        @mouseleave="unhover"
        @mouseover="hover"
        v-show="mobileSidebarOpened || !isMobile"
      >
        <div class="sidebar-header" v-if="!isMobile">
          <div class="logo">
            <a href="https://oneragtime.com">
              <img
                :fluid="true"
                src="@/assets/images/OneRagtime-Logo-2017-WEB-v2.svg"
                width="100"
              />
            </a>
          </div>
          <div class="hamburger">
            <ORTIcon icon="menu-02"/>
          </div>
        </div>
        <div v-else class="sidebar-header justify-content-start" @click="setSidebarOpen(false)">
          <ORTIcon icon="x" size="30px"/>
        </div>
        <ul class="menu">
          <li  v-if="isActiveStartup" :class="['menu-item', {'active-page': activePage('/')}]">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'Home', params: { id: getUserId } }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="home-line" size="18px"/>
                <p class="menu-item">Home</p>
              </a>
            </router-link>
          </li>
          <li  v-if="isActiveStartup" :class="['menu-item', {'active-page': activePage('/founder-fundraisings')}]">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'MyFundraisings', params: { id: getUserId } }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="bar-chart-square-01" size="18px"/>
                <p class="menu-item">My fundraisings</p>
              </a>
            </router-link>
          </li>
          <li v-else :class="['menu-item', {'active-page': activePage('/portfolio')}]">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'Portfolio', params: { id: getUserId } }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="home-line" size="18px"/>
                <p class="menu-item">My portfolio</p>
              </a>
            </router-link>
          </li>
          <li v-if="!isActiveStartup" :class="['menu-item', {'active-page': activePage('/funds')}]">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'FundSection' }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="layers-three-01" size="18px"/>
                <p class="menu-item">Funds</p>
              </a>
            </router-link>
          </li>
          <li v-if="!isActiveStartup" :class="['menu-item', {'active-page': activePage('/club-deal')}]">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'ClubdealSection' }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="bar-chart-square-01" size="18px"/>
                <p class="menu-item">Club deals</p>
              </a>
            </router-link>
          </li>
          <!-- <li v-if="!isActiveStartup" :class="['menu-item, external-link']">
              <a @click="NavigateAndClose(navigate)" class="sidebar-router" target="_blank" href="https://careers.oneragtime.com/jobs">
                <ORTIcon icon="rocket-02" size="18px"/>
                <p class="menu-item">Community</p>
              </a>
          </li> -->
          <li class="menu-item" v-if="isMobile && !isActiveStartup">
            <router-link v-slot="{ navigate }" class="sidebar-router" :to="{ name: 'MyProfile' }">
              <a @click="NavigateAndClose(navigate)">
                <ORTIcon icon="settings-01" size="18px"/>
                <p class="menu-item">Settings</p>
              </a>
            </router-link>
          </li>
          <li class="menu-item" v-if="isMobile">
              <div @click="logout" class="sidebar-router">
                <a>
                  <ORTIcon icon="log-out-01" size="18px"/>
                  <p class="menu-item">Logout</p>
                </a>
              </div>
          </li>
          <hr class="w-100" v-if="hasBetaAccess && !isMobile"/>
          <li v-if="hasBetaAccess && !isMobile" class="beta-switch">
            <div :class="['beta-icon']">
              <ORTIcon icon="code-square-01" size="18px" :color="betaIconColor"/>
              <div :class="['beta-circle', {'beta-active': BetaSwitcher}]"></div>
            </div>
            <p class="menu-item">Beta version</p>
            <FormSwitcher
              :value="betaEnabled"
              class="switcher"
              @send_switch_value="toggleBetaVersion"
              >
            </FormSwitcher>
          </li>
        </ul>
        <div class="entity-wrapper">
          <SelectableEntitiesList
            ref="selectable-entities-list"
            @close-sidebar="closeSidebar"
          />
        </div>
        <div class="account-wrapper">
          <hr class="down-hr"/>
          <div class="account-info">
            <CustomImage
              class="profile-picture mt-1 ml-1"
              :src="userImage"
              width="28px"
              height="28px"
              is-circle
            >
            </CustomImage>
            <div class="account-extra">
              <p class="text-md--bold">{{ userCompleteName }}</p>
              <p class="text-sm">{{userEmail}}</p>
            </div>
            <div v-if="!isMobile" @click.stop="openProfileControls" class="account-extra  ml-auto mt-1">
              <ORTIcon icon="dots-vertical" class="three-dots" size="20px"/>
            </div>
            <ProfileControls
              ref="popup"
              class="profileControls"
              v-show="profileControls"
              :isActiveStartup="isActiveStartup"
              @logout="logout"
              @close-sidebar="closeSidebar"
            />
          </div>
        </div>
      </div>
    </transition>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ORTIcon from '@/ort-lib/components/ORTIcon.vue';
import SelectableEntitiesList from '@/components/molecules/lists/SelectableEntitiesList.vue';
import CustomImage from '@/components/atoms/images/CustomImage.vue';
import DefaultNaturalAvatar from '@/assets/images/no-avatar-natural.png';
import FormSwitcher from '@/components/atoms/forms/FormSwitcher.vue';
import ProfileControls from '@/components/sections/sidebar/ProfileControls.vue';

export default {
  name: 'SideBar',
  components: {
    ORTIcon,
    SelectableEntitiesList,
    CustomImage,
    FormSwitcher,
    ProfileControls,
  },
  data() {
    return {
      isHovered: false,
      profileControls: false,
      BetaSwitcher: false,
    };
  },
  computed: {
    ...mapGetters([
      'getBasicInfo',
      'getUserId',
      'isActivePartner',
      'isActiveStartup',
      'getSidebarOpen',
      'hasBetaAccess',
      'betaEnabled',
    ]),
    userCompleteName() {
      return this.getBasicInfo.full_name;
    },
    userEmail() {
      return this.getBasicInfo.email;
    },
    userImage() {
      return DefaultNaturalAvatar;
    },
    entityType() {
      return this.$store.getters.refreshtokenPayload?.active_profile?.entity_type;
    },
    mobileSidebarOpened() {
      return this.getSidebarOpen;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    betaIconColor() {
      return this.activeSwitcher ? 'primary' : 'grey';
    },
  },
  methods: {
    ...mapActions([
      'logout',
      'getUserBasicInfo',
      'updateUserBasicInfo',
      'userInfoAnalytics',
    ]),
    ...mapMutations([
      'setCurrentUserId',
      'setSidebarOpen',
    ]),
    hover() {
      this.isHovered = true;
    },
    async toggleBetaVersion(value) {
      this.BetaSwitcher = value;
      await this.updateUserBasicInfo({
        userId: this.getUserId,
        beta_status: this.betaEnabled ? 'beta_disabled' : 'beta_enabled',
      });
    },
    NavigateAndClose(navigate) {
      this.setSidebarOpen(false);
      navigate();
      window.scrollTo(0, 0);
    },
    logout() {
      this.setSidebarOpen(false);
      this.$emit('logout');
    },
    openProfileControls() {
      this.profileControls = true;
    },
    unhover() {
      this.profileControls = false;
      this.isHovered = false;
    },
    checkIfClickedOutside(e) {
      const { popup } = this.$refs;
      if (popup && popup.$el.contains(e.target)) {
        return;
      }
      this.profileControls = false;
    },
    closeSidebar() {
      this.isHovered = false;
      this.setSidebarOpen(false);
    },
    activePage(route) {
      return this.$route.path === route;
    },
  },
  async mounted() {
    await this.getUserBasicInfo(this.getUserId);
    this.BetaSwitcher = this.betaEnabled;
    await this.userInfoAnalytics();
  },
  watch: {
    getBasicInfo: {
      async handler() {
        await this.getUserBasicInfo(this.getUserId);
      },
      deep: true,
    }
  }
};

</script>
<style lang="scss"  scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
  opacity: 1;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}
.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}
.sidebar-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 317px;
  top: 0;
  left: 0;
  right: auto;
  height: 100vh;
  border-right: $grey-600 1px solid;
  background-color: white;
  padding: 16px;
  z-index: 5;
  @media (min-width: 768px) {
    width: 81px;
    left: 0;
    right: auto;
    padding: 16px;
    transition: width 0.5s ease;
    p, .account-extra {
      overflow: hidden;
    }
  }
}
.active {
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
}
.sidebar-hover, .active {
  width: 317px;
  .sidebar-header {
    justify-content: space-between;
    .logo {
      max-width: none;
      opacity: 1;
    }
  }
  .menu {
    .menu-item {
      max-width: none;
    }
    .beta-switch {
      p {
        margin-left: 12px;
      }
    }
  }
  .entity-wrapper {
    max-width: none;
    overflow: none;
  }
  .account-info {
    .account-extra {
      margin-left: 12px;
      max-width: none;
    }
    .profileControls {
      max-width: none;
      opacity: 1;
    }
  }
  hr {
    display: block;
  }
  .sidebar-router {
    width: 100%;
  }
  .switcher {
    padding-left: 11px;
    max-width: none;
  }
}
.sidebar-header {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  .logo {
    opacity: 0;
    max-width: 0;
    overflow: hidden;
    padding-left: 12px;
  }
}
.menu {
  list-style: none;
  padding-inline-start: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0 !important;

  .sidebar-router a, .beta-switch, .external-link a{
    display: flex;
    align-items: center;
    @extend .text;
    p {
      margin: 0 0 0 12px;
      max-width: 0;
      white-space: nowrap;
      color: $primary-label;
      line-height: 1.3rem;
      &:hover {
        color: $primary;
        background-color: transparent;
      }
    }
    .beta-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 45px;
      min-height: 45px;
      border-radius: 50%;
      position: relative;

      .beta-circle {
        position: absolute;
        width: 0px;
        height: 0px;
        background-color: $primary;
        opacity: 0.1;
        border-radius: 50%;
        transition: width 0.2s, height 0.2s;
      }
      .beta-active {
        width: 49px;
        height: 49px;
        transition: width 0.2s, height 0.2s;
      }
    }
  }
  .beta-switch {
    padding: 0;
    p {
      margin: 0;
    }
    :deep(label) {
      margin: 0;
    }
  }
  li {
    display: flex;
    cursor: pointer;
    margin-bottom: 14px;
    @extend .text;
    padding: 8px 12px;
    border-radius: 6px;
  }
  .menu-item:hover, .external-link:hover{
      background-color: $cobalt-600;
      color: $primary;
    }
  .active-page {
    background-color: $cobalt-600;
    color: $primary;
  }
}
.entity-wrapper {
  max-width: 0;
  overflow: hidden;
  height: 100%;
  align-items: end;
  white-space: nowrap;
  margin-top: auto;
}
.down-hr {
  width: 100%;
  display: none;
}
.switcher {
  max-width: 0;
  overflow: hidden;
}
.account-info {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 0.35rem;
  p {
    white-space: nowrap;
    margin: 0;
  }
  .account-extra {
    max-width: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 0;
    overflow: hidden;
  }
  .profileControls {
    max-width: 0;
    overflow: hidden;
    opacity: 0;
  }
  .three-dots {
    background-color: $secondary-label;
    cursor: pointer;
  }
}
</style>
