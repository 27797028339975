import { axiosCore } from '@/plugins/axios';

const state = {
  isPortfolioLoading: false,

  /** Investment KPI indexed by offer option
   *
   * KPI example: {
   *  "Club deal €": [{ name: "Jellysmack", committed_amount: 30_000, ... }]
   *  "Rhapsody II": [{ name: "Jellysmack", committed_amount: 30_000, ... }]
   * }
   */
  kpis: {},

  /** Investment KPI in funds
   *
   * Example: [{ name: "Rhapsody II", committed_amount: 100_000, ... }]
   */
  ownKpisFunds: [],
};

const getters = {
  getKPIs: (state) => state.kpis,
  getownKpisFunds: (state) => state.ownKpisFunds,
  getIsPortfolioLoading: (state) => state.isPortfolioLoading,
};

const mutations = {
  setKPIs: (state, { name, data, isBeta }) => { 
    state.kpis[name] = data;
    if (isBeta) {
      // TODO: remove after we validate coonversion rate
      let current_rate = 1 / data[0].current_rate
      for (let i = 0; i < data.length; i++) {
        state.kpis[name][i].committed_amount = data[i].committed_amount_eur;
        state.kpis[name][i].total_value_gross *= current_rate;
        state.kpis[name][i].unrealized_gross *= current_rate;
        state.kpis[name][i].unrealized_net *= current_rate;
        if (data[i].name == "Jellysmack") {
          state.kpis[name][i].realized /= 1.2;
        }
      }
    }
  },
  setownKpisFunds: (state, data) => { state.ownKpisFunds = state.ownKpisFunds.concat(data); },
  resetKPIs: (state, _) => { state.ownKpisFunds = []; state.kpis = {}; },
  setIsPortfolioLoading: (state, data) => { state.isPortfolioLoading = data; },
};

const actions = {
  async fetchKPIs({ commit, dispatch, getters }, investorId) {
    const isBeta = Number(getters.betaEnabled);  // Shitty variable for shitty tests
    commit('resetKPIs');
    commit('setIsPortfolioLoading', true);
    investorId = investorId || getters.portfolioInvestorId;
    try {
      const response = await axiosCore.get(`events/investor/${investorId}/kpis?currency=EUR&is_beta=${isBeta}`);
      const data = response.data?.filter((a) => !a.is_fund);
      if (data?.length) {
        commit('setKPIs', { name: 'Club deal (in €)', data, isBeta: isBeta });
      }

      const response_usd = await axiosCore.get(`events/investor/${investorId}/kpis?currency=USD`);
      const data_usd = response_usd.data?.filter((a) => !a.is_fund);
      if (data_usd?.length) {
        commit('setKPIs', { name: 'Club deal (in $)', data: data_usd, isBeta: isBeta });
      }

      const funds = response.data?.filter((kpi) => kpi.fund_investor_id);
      for (const fund in funds) {
        const response_funds = await axiosCore.get(`events/investor/${funds[fund].fund_investor_id}/kpis`);
        commit('setKPIs', { name: funds[fund].name, data: response_funds.data, isBeta: isBeta });
        commit('setownKpisFunds', funds[fund]);
      }
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsPortfolioLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
